body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover{
  background: #115d9c;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    font-family: 'Courier New';
    font-weight: 700;
    font-size: 14px;
    background: #04ADA8  !important ;
    border-color: #04ADA8  !important ;
}

.panel-default > .panel-heading {
    background-color: #04ADA8  !important ;
    border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    color: #fff;
}

.react-form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.tab{
    /*
    background: #f5f5f5;
    color: #000;
    padding: 5px 65px;
    border: 1px solid #04ADA8;
    border-radius: 5px 5px 0 0;
    top: 6px;
    position: relative;
	*/
	border: none;
	top: 0;
	background: #E7F0F7;
	color: #1A3552;
	opacity: 1;
	font: bold 12px/16px ;
	font-weight: 600;
	font-size: 18px;
}
.tab-active{
    /*
    background: #04ADA8;<h4 style={{ marginLeft: "15px",marginTop : "20px" , paddingTop: "10px" ,color: "#000000"}}>
    color: #fff;
    border: 1px solid #04ADA8;
    border-radius: 5px 5px 0 0;
    padding: 10px 65px;
    top: 0;
    position: relative;
	*/
	border: none;
	top: 0;
	background: #E7F0F7;
	color: #11ABA6;
	opacity: 1;
	font: bold 12px/16px ;
	font-weight: 600;
	font-size: 18px;
}
.rwt_tablist{
	top: 70px;
	left: 236px;
	width: 1044px;
	height: 60px;
	background: #E7F0F7 0% 0% no-repeat padding-box;
	opacity: 1;
}

tr > th{
  padding: 8px 5px;
}
tr > td{
  padding: 6px 5px;
}
tr:nth-child(even){
  background-color: rgb(249,249,249);
}
.metingtable tr:nth-child(odd){
  background-color: rgb(251,251,251);
}
.metingtable tr:nth-child(even){
  background-color: #fff;
}
.metingtable td:nth-child(even){
  color: rgb(142, 139, 139);
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

/* Customize React DatePicker  */

.react-datepicker-wrapper{
  width:100% !important;
}
.react-datepicker__header{
  background-color:#fff !important;
  border-bottom: none !important;
}
.react-datepicker{
  width: 100% !important;
  border: 1px solid #e0dbdb !important;
  border-radius: 5px !important;
}
.react-datepicker__month-container{
  width: 100% !important;
  font-size: 14px !important;
}
.react-datepicker__navigation--next{
  border-left-color: #272727 !important;
}
.react-datepicker__navigation--previous{
  border-right-color: #272727 !important;
}
.react-datepicker-popper{
  width:87%;
  top: -6px !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-size: 13px !important;
  padding: 6px 0 !important;
}
.react-datepicker__day-names{
  font-weight: 600 !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  width: 2.7rem !important;
  margin: 3px !important;
  padding: 2px !important;
}
.react-datepicker__navigation{
  height: 11px !important;
  top: 16px !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
  border-bottom-color: #ffffff !important; 
}
