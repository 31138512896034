/*@import url('https://cmpplus.co.uk/common-resource/resource/libraries/css/bootstrap.min.css');
@import url('https://cmpplus.co.uk/common-resource/resource/libraries/css/CSL.Client.UI.Font.css');
@import url('https://cmpplus.co.uk/common-resource/resource/libraries/css/CSL.Client.UI.Common.Header.Footer.min.css');
@import url('https://cmpplus.co.uk/common-resource/resource/SMR_UI/css/CSL.SMR.UI.style.min.css');
@import url('https://cmpplus.co.uk/common-resource/resource/libraries/css/font-awesome.min.css');
@import url('https://cmpplus.co.uk/common-resource/resource/libraries/javascripts/select2/css/select2.min.css');
@import url('./Components/Tabs/style.css');*/


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #979797 ! important;
  padding-top: 5px ! important;
  font-weight: normal !important;
}
.react-date-picker__inputGroup__input {
  color: #979797 ! important;
  font-weight: normal !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
